<template>
  <div style="height: inherit">
    <atractivo-form
      :is-toggle-sidebar.sync="isToggleSidebar"
      :form-type="formType"
      :data-edit="dataEdit"
      @refetch-data="refetchData"
      @error-data="showError"
    />
    <modal-multimedia
      ref="refModalMultimedia"
      :data-info="dataInfo"
    />
    <modal-qr
      ref="refModalQr"
    />
    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results">
                {{ totalItems }} resultados encontrados
              </div>
            </div>
            <div class="view-options d-flex">
              <b-button
                variant="primary"
                @click="openForm('new')"
              >
                <span class="text-nowrap">Agregar Nuevo</span>
              </b-button>

              <!-- Sort Button -->
              <b-dropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :text="sortBy.text"
                right
                variant="outline-primary"
                class="ml-1"
              >
                <b-dropdown-item
                  v-for="sortOption in sortByOptions"
                  :key="sortOption.value"
                  @click="sortBy=sortOption"
                >
                  {{ sortOption.text }}
                </b-dropdown-item>
              </b-dropdown>

              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  <feather-icon
                    :icon="option.icon"
                    size="18"
                  />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="query"
              placeholder="Buscar registro"
              class="search-product"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <!--XXX LAE FILTROS ACA -->

    <div
      v-if="isBusy"
      class="text-center text-primary my-2"
    >
      <b-spinner
        variant="primary"
        class="align-middle"
      />
      <strong class="ml-1">Cargando...</strong>
    </div>

    <!-- Lista de Registros -->
    <section
      :class="itemView"
      style="margin-top: 1rem;"
    >
      <b-card
        v-for="registro in registros"
        :key="registro.id"
        class="ecommerce-card bg-dark"
        no-body
      >
        <div
          class="item-img text-center"
          style="padding-bottom: 0px; margin: auto;"
        >
          <b-link
            :href="'#'"
            target="_blank"
          >
            <b-img
              v-if="registro.multimedia && registro.multimedia.length > 0"
              :alt="`${registro.nombre}`"
              fluid
              class="card-img-top"
              :src="`${pathMultimedia}/byUrl?isthumb=true&url=${registro.multimedia[0].url}`"
            />
            <b-img
              v-else
              :alt="`${registro.nombre}`"
              fluid
              class="card-img-top"
              :src="require('@/assets/images/checkapp/notimage.png')"
            />
          </b-link>
        </div>

        <!-- Registro Detalle -->
        <b-card-body class="bg-white">
          <div class="item-wrapper">
            <div class="item-rating">
              <ul class="unstyled-list list-inline">
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item"
                  :class="{'ml-25': star-1}"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="16"
                    :class="[{'fill-current': star <= registro.ranking}, star <= registro.ranking ? 'text-warning' : 'text-muted']"
                  />
                </li>
              </ul>
            </div>
            <div>
              <b-badge
                v-if="registro.estadoTourPaquete === 'REGISTRADO'"
                variant="light-primary"
              >
                {{ registro.estadoTourPaquete }}
              </b-badge>
              <b-badge
                v-if="registro.estadoTourPaquete === 'PUBLICADO'"
                variant="light-success"
              >
                {{ registro.estadoTourPaquete }}
              </b-badge>
              <b-badge
                v-if="registro.estadoTourPaquete === 'DESACTIVADO'"
                variant="light-danger"
              >
                {{ registro.estadoTourPaquete }}
              </b-badge>
            </div>
          </div>
          <h6 class="item-name">
            <b-link
              v-b-tooltip.hover.top="registro.titulo"
              class="text-body"
              :href="registro.url"
              target="_blank"
            >
              {{ registro.nombre }}
            </b-link>
            <b-card-text class="item-company">
              By <b-link class="ml-25">
                {{ registro.brand }}
              </b-link>
            </b-card-text>
          </h6>
          <b-card-text class="item-description">
            <div class="item-wrapper">
              <div>
                {{ registro.destino.nombre }}
              </div>
              <div>
                <b-badge
                  v-if="registro.urlSlug != null"
                  pill
                  variant="light-dark"
                  class="d-block"
                >
                  Publicado
                </b-badge>
                <b-badge
                  v-else
                  pill
                  variant="light-danger"
                  class="d-block"
                >
                  Registrado
                </b-badge>
              </div>
            </div>
          </b-card-text>
        </b-card-body>

        <!-- Action Buttons -->
        <div class="item-options text-center">
          <b-button
            v-b-tooltip.hover.top="'Editar'"
            variant="primary"
            class="btn-wishlist remove-wishlist"
            :to="{ name: 'rt-plataforma-info-atractivo-editar', params: { id: registro.idAtractivo } }"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="'Código QR'"
            :variant="registro.qrCode !== null ? 'info' : 'secondary'"
            class="btn-wishlist remove-wishlist"
            @click="getModalQr(registro)"
          >
            <feather-icon icon="GridIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="'Imágenes Destacadas'"
            variant="warning"
            class="btn-wishlist remove-wishlist"
            @click="imagesRegister(registro)"
          >
            <feather-icon icon="ImageIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="'Eliminar'"
            variant="danger"
            class="btn-cart move-cart"
            @click="deleteRegister(registro)"
          >
            <feather-icon
              icon="Trash2Icon"
              class="mr-50"
            />
          </b-button>
        </div>
      </b-card>
    </section>

    <!-- Pagination -->
    <section>
      <b-row
        v-if="totalItems > 0"
      >
        <b-col cols="12">
          <b-pagination
            v-model="page"
            :total-rows="totalItems"
            :per-page="perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <filter-sidebar
        :experiencias="experiencias"
        :destinos-turisticos="destinosTuristicos"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @apply-filter="applyFilter"
      />
    </portal>
  </div>
</template>

<script>
import Vue from 'vue'
import store from '@/store'
import moment from 'moment'
import {
  BBadge, BDropdown, BDropdownItem, BFormRadioGroup, BFormRadio, BRow, BCol, BInputGroup, BInputGroupAppend, BFormInput, BCard, BCardBody, BLink, BImg, BCardText, BButton, BPagination, BSpinner, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useNotify } from '@/helpers/toast'
import { ref, watch } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import config from '@/services/config'
import FilterSidebar from './AtractivoFilterSidebar.vue'
import { useShopUi } from './useECommerceShop'
import { useEcommerceUi } from './useEcommerce'
import AtractivoForm from './AtractivoForm.vue'
import ModalMultimedia from '../../../components/multimedia/ModalMultimedia.vue'
import ModalQr from '../code_qr/ModalQr.vue'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    ModalMultimedia,
    // BSV
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    BSpinner,

    // SFC
    FilterSidebar,
    AtractivoForm,
    ModalQr,
  },
  setup(props, context) {
    const { notify } = useNotify(context)
    const modalshow = ref(false)
    const dataEdit = ref({})
    const dataInfo = ref({})
    const isToggleSidebar = ref(false)
    const registros = ref({})
    const page = ref(1)
    const query = ref('')
    const perPage = ref(12)
    const totalItems = ref(0)
    const totalPages = ref(0)
    const dataReady = ref(false)
    const isBusy = ref(false)
    const experiencias = ref([])
    const destinosTuristicos = ref([])
    const ftidDestinoTuristico = ref('-1')
    const ftidClasificacion = ref('-1')
    const formType = ref('')
    const refModalMultimedia = ref(null)
    const refModalQr = ref(null)
    const idEmpresa = ref(0)
    const idTourPaquete = ref(0)
    const { pathRepo, contextBO } = config
    const pathMultimedia = ref(`${pathRepo}${contextBO}/api/v1/multimedia`)

    const sortByOptions = ref([
      {
        text: 'Todos',
        value: '',
      },
      {
        text: 'Sólo Registrados',
        value: 'R',
      },
      {
        text: 'Sólo Publicados',
        value: 'P',
      },
    ])

    const sortBy = ref({ text: 'Todos', value: '' })
    //

    const openForm = type => {
      formType.value = type
      isToggleSidebar.value = true
    }

    const imagesRegister = item => {
      dataInfo.value.alertMsgTitle = 'Imágenes registradas para el atractivo.'
      dataInfo.value.alertMsgSubTitle = 'Dimensiones recomendadas 1000x667, cantidad mínima ideal: 7 imágenes.'
      dataInfo.value.alertIcon = 'InfoIcon'
      refModalMultimedia.value.idSeccion = item.idAtractivo
      refModalMultimedia.value.idDestinoTuristico = item.destino.idDestinoTuristico
      refModalMultimedia.value.seccion = 'ATRACTIVOS'
      refModalMultimedia.value.orientacion = 'H'
      refModalMultimedia.value.loadRegisters()
      refModalMultimedia.value.openModal()
      // modalshow.value = true
    }

    const getModalQr = item => {
      refModalQr.value.idSeccion = item.idAtractivo
      refModalQr.value.idDestino = item.destino.idDestinoTuristico
      refModalQr.value.seccion = 'Atractivos'
      refModalQr.value.srcQR = item.qrCode
      refModalQr.value.openModal()
    }

    const editRegister = item => {
      dataEdit.value = item
      formType.value = 'edit'
      isToggleSidebar.value = true
    }

    const getDestinos = async () => {
      await store.dispatch('catalogo/DESTINO_FIND_ALL', {
        query: '',
        page: -1,
        limit: -1,
        sortBy: '',
      })
        .then(response => {
          if (response) {
            destinosTuristicos.value = response.items
          }
        })
    }

    const getClasificaciones = async seccion => {
      await store.dispatch('catalogo/CLASIFICACION_FIND_ALL_BY_SECCION', {
        seccion,
      })
        .then(response => {
          if (response) {
            experiencias.value = response.data
          }
        })
    }

    const getRegistros = async () => {
      isBusy.value = true
      await store.dispatch('plataforma/ATRACTIVO_FIND_ALL', {
        query: query.value,
        page: page.value,
        limit: perPage.value,
        sortBy: 'idAtractivo%7CASC',
        idDestino: ftidDestinoTuristico.value,
        estadoPublicacion: sortBy.value.value,
        idClasificacion: ftidClasificacion.value,
      })
        .then(response => {
          registros.value = response.items
          totalItems.value = response.totalItems
          totalPages.value = response.totalPage
        })
      isBusy.value = false
    }

    const applyFilter = filters => {
      if (filters.value.idsDT !== null && filters.value.idsDT.length > 0) {
        ftidDestinoTuristico.value = filters.value.idsDT.join()
      } else {
        ftidDestinoTuristico.value = '-1'
      }
      if (filters.value.idsEXP !== null && filters.value.idsEXP.length > 0) {
        ftidClasificacion.value = filters.value.idsEXP.join()
      } else {
        ftidClasificacion.value = '-1'
      }
      /* if (filters.value.idsEXP !== null && filters.value.idsEXP.length > 0) {
        ftidTipoDirectorio.value = filters.value.idsTST.join()
      } else {
        ftidTipoDirectorio.value = '-1'
      }
      if (filters.value.idsDT !== null && filters.value.idsDT.length > 0) {
        ftidDestinoTuristico.value = filters.value.idsDT.join()
      } else {
        ftidDestinoTuristico.value = '-1'
      } */
      getRegistros()
    }

    const refetchData = response => {
      let message = 'El proceso se ha ejecutado satisfactoriamente.'
      if (response.message) {
        message = response.message
      }
      notify('Operación Extitosa', message, 'success')
      getRegistros()
    }

    const deleteRegister = item => {
      Vue.swal({
        title: 'Está seguro?',
        text: 'Va eliminar un registro!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store
            .dispatch('agencia/TOUR_PAQUETE_DELETE', {
              id: item.idTourPaquete,
            })
            .then(() => {
              notify('Operación Exitosa', 'El proceso se ha ejecutado satisfactoriamente.', 'success')
              getRegistros()
            })
            .catch(error => {
              let errorMessage = 'Intentelo en otro momento.'
              if (error.response) {
                errorMessage = error.response.data.message
              }
              notify('Ha ocurrido un error', errorMessage, 'danger')
            })
        }
      })
    }

    const loadData = async () => {
      await getDestinos()
      await getClasificaciones('EXPERIENCIA_TURISTICA')
      await getRegistros()
    }

    loadData()

    watch([query, page, sortBy], () => {
      getRegistros()
    })

    const showError = error => {
      let errorMessage = 'Intentelo en otro momento.'
      if (error.response) {
        if (error.response.data.message) {
          errorMessage = error.response.data.message
        }
        if (error.response.data.errorMessage) {
          errorMessage = error.response.data.errorMessage
        }
      }
      notify('Ha ocurrido un error', errorMessage, 'danger')
    }

    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

    const {
      itemView, itemViewOptions, totalProducts,
    } = useShopUi()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      // Nuevos
      modalshow,
      imagesRegister,
      getModalQr,
      editRegister,
      deleteRegister,
      refModalMultimedia,
      refModalQr,
      dataEdit,
      dataInfo,
      isToggleSidebar,
      registros,
      page,
      query,
      perPage,
      totalItems,
      totalPages,
      dataReady,
      isBusy,
      destinosTuristicos,
      experiencias,
      getRegistros,
      refetchData,
      //
      // useShopFiltersSortingAndPagination
      sortBy,
      sortByOptions,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProducts,
      toggleProductInWishlist,
      handleCartActionClick,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
      showError,
      openForm,
      formType,

      idEmpresa,
      idTourPaquete,
      pathMultimedia,
      applyFilter,
    }
  },
  methods: {
    getDateFmt(fecha) {
      return moment(String(fecha)).format('DD/MM/YYYY')
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.ecommerce-application .grid-view {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr !important;
    -webkit-column-gap: 2rem;
    -moz-column-gap: 2rem;
    column-gap: 2rem;
}
</style>

<template>
  <b-sidebar
    id="new-edit-sidebar"
    :visible="isToggleSidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => toggleSidebar(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titleForm }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <!-- Form -->
      <b-form
        class="p-2"
        @reset.prevent="resetForm"
      >
        <h6 class="text-dark">
          <feather-icon icon="ClipboardIcon" />
          <span class="align-middle ml-25">Ficha Básica</span>
        </h6>

        <hr style="border-top: 2px solid #161d31;margin-bottom: 1rem !important;margin-top: 0 !important;">

        <!-- Destino -->
        <b-form-group
          label="Seleccione Destino"
          label-for="destino"
        >
          <v-select
            id="destino"
            v-model="destinoSel"
            :reduce="m => m.idDestinoTuristico"
            label="nombre"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="destinos"
            :clearable="false"
          />
        </b-form-group>

        <!-- Nombre -->
        <b-form-group
          label="Nombre Atractivo"
          label-for="nombre"
        >
          <b-form-input
            id="nombre"
            v-model="formData.nombre"
          />
        </b-form-group>

        <!-- Descripcion -->
        <b-form-group
          label="Descripción"
          label-for="descripcion"
        >
          <quill-editor
            id="descripcion"
            v-model="formData.descripcion"
            :options="editorOption"
            class="border-bottom-0"
          />
        </b-form-group>

        <!-- Particularidad -->
        <b-form-group
          label="Particularidad"
          label-for="particularidad"
        >
          <quill-editor
            id="particularidad"
            v-model="formData.particularidad"
            :options="editorOption"
            class="border-bottom-0"
          />
        </b-form-group>

        <h6 class="text-dark">
          <feather-icon icon="MapPinIcon" />
          <span class="align-middle ml-25">Datos de Ubicación</span>
        </h6>

        <hr
          style="border-top: 2px solid #161d31;margin-bottom: 1rem !important;margin-top: 0 !important;"
        >

        <b-form-group
          label="Departamento"
          label-for="dpto"
        >
          <v-select
            v-model="dptoSel"
            :reduce="m => m.idUbigeo"
            label="descripcion"
            :clearable="false"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="dptos"
            @input="getUbigeos('prov', dptoSel.slice(0, 2))"
          />
        </b-form-group>

        <b-form-group
          label="Provincia"
          label-for="prov"
        >
          <v-select
            v-model="provSel"
            :reduce="m => m.idUbigeo"
            label="descripcion"
            :clearable="false"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="provs"
            @input="getUbigeos('dist', provSel.slice(0, 4))"
          />
        </b-form-group>

        <b-form-group
          label="Distrito"
          label-for="dist"
        >
          <v-select
            v-model="distSel"
            :reduce="m => m.idUbigeo"
            label="descripcion"
            :clearable="false"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="dists"
          />
        </b-form-group>

        <b-form-group
          label="Dirección"
          label-for="direccion"
        >
          <b-form-input
            id="direccion"
            v-model="formData.direccion"
          />
        </b-form-group>

        <b-form-group
          label="Referencia"
          label-for="referencia"
        >
          <b-form-input
            id="referencia"
            v-model="formData.referencia"
          />
        </b-form-group>

        <b-form-group
          label="Latitud"
          label-for="latitud"
        >
          <b-form-input
            id="latitud"
            v-model="formData.latitud"
            type="number"
          />
        </b-form-group>

        <b-form-group
          label="Longitud"
          label-for="longitud"
        >
          <b-form-input
            id="longitud"
            v-model="formData.longitud"
            type="number"
          />
        </b-form-group>

        <h6 class="text-dark">
          <feather-icon icon="BookmarkIcon" />
          <span class="align-middle ml-25">Categorización Mincetur</span>
        </h6>

        <hr
          style="border-top: 2px solid #161d31;margin-bottom: 1rem !important;margin-top: 0 !important;"
        >

        <b-form-group
          label="Categoría"
          label-for="categoria"
        >
          <v-select
            v-model="categoriaRecursoSel"
            :reduce="m => m.idCategoriaRecurso"
            label="descripcion"
            :clearable="false"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="categoriasRecurso"
            @input="getTiposRecurso()"
          />
        </b-form-group>

        <b-form-group
          label="Tipo de Recurso"
          label-for="tipoRecurso"
        >
          <v-select
            v-model="tipoRecursoSel"
            :reduce="m => m.idTipoRecurso"
            label="descripcion"
            :clearable="false"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="tiposRecurso"
            @input="getSubTiposRecurso()"
          />
        </b-form-group>

        <b-form-group
          label="Sub Tipo de Recurso"
          label-for="subTipoRecurso"
        >
          <v-select
            v-model="subTipoRecursoSel"
            :reduce="m => m.idSubTipoRecurso"
            label="descripcion"
            :clearable="false"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="subTiposRecurso"
          />
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-5">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="button"
            @click="saveForm"
          >
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancelar
          </b-button>
        </div>

      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BFormInput, BSidebar, BForm, BFormGroup, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isToggleSidebar',
    event: 'update:is-toggle-sidebar',
  },
  props: {
    isToggleSidebar: {
      type: Boolean,
      required: true,
    },
    dataEdit: {
      type: Object,
      required: false,
      default: null,
    },
    formType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const formData = ref({})
    const isBusy = ref(false)
    const titleForm = ref('')
    const destinos = ref([])
    const destinoSel = ref(null)
    const dptoSel = ref('220000')
    const provSel = ref(null)
    const distSel = ref(null)
    const dptos = ref([])
    const provs = ref([])
    const dists = ref([])
    const categoriasRecurso = ref([])
    const categoriaRecursoSel = ref({})
    const tiposRecurso = ref([])
    const tipoRecursoSel = ref({})
    const subTiposRecurso = ref([])
    const subTipoRecursoSel = ref({})

    const configDP = ref(
      {
        enableTime: false,
        dateFormat: 'd/m/Y',
        altInput: true,
        altFormat: 'd/m/Y',
      },
    )

    const toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'],
      // ['blockquote', 'code-block'],
      // [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      // [{ script: 'sub' }, { script: 'super' }],
      // [{ indent: '-1' }, { indent: '+1' }],
      // [{ direction: 'rtl' }],
      // [{ size: ['small', false, 'large', 'huge'] }],
      // [{ header: [1, 2, 3, 4, 5, 6, false] }],
      // [{ color: [] }, { background: [] }],
      // [{ font: [] }],
      [{ align: [] }],
      // ['clean'],
    ]

    const editorOption = {
      modules: {
        toolbar: toolbarOptions,
      },
      placeholder: 'Ingrese descripción',
    }

    const resetForm = () => {
      formData.value = {}
      destinoSel.value = ''
    }

    const getUbigeos = async (type, codUbigeo) => {
      await store
        .dispatch('catalogo/UBIGEO_FIND_ALL', {
          get: type,
          codUbigeo,
        })
        .then(response => {
          if (type === 'dpto') {
            dptos.value = response
            provSel.value = null
            distSel.value = null
            provs.value = []
            dists.value = []
          }
          if (type === 'prov') {
            provs.value = response
            provSel.value = null
            distSel.value = null
            dists.value = []
          }
          if (type === 'dist') {
            dists.value = response
          }
        })
        .catch(error => {
          emit('error-data', error)
        })
    }

    const getDestinos = async () => {
      await store
        .dispatch('catalogo/DESTINO_FIND_ALL', {
          query: '',
          page: -1,
          limit: -1,
          sortBy: '',
        })
        .then(response => {
          if (response.items) {
            destinos.value = response.items
          }
        })
        .catch(error => {
          emit('error-data', error)
        })
    }

    const getCategoriasRecurso = async () => {
      await store
        .dispatch('catalogo/CATEGORIA_RECURSO_FIND_ALL', {
          query: '',
          page: 1,
          limit: 1000,
          sortBy: '',
        })
        .then(response => {
          if (response.items) {
            categoriasRecurso.value = response.items
            tipoRecursoSel.value = {}
            subTipoRecursoSel.value = {}
          }
        })
        .catch(error => {
          emit('error-data', error)
        })
    }

    const getTiposRecurso = async () => {
      await store
        .dispatch('catalogo/TIPO_RECURSO_FIND_ALL', {
          idCategoriaRecurso: categoriaRecursoSel.value,
          query: '',
          page: 1,
          limit: 1000,
          sortBy: '',
        })
        .then(response => {
          if (response.items) {
            tiposRecurso.value = response.items
            tipoRecursoSel.value = {}
            subTipoRecursoSel.value = {}
          }
        })
        .catch(error => {
          emit('error-data', error)
        })
    }

    const getSubTiposRecurso = async () => {
      await store
        .dispatch('catalogo/SUBTIPO_RECURSO_FIND_ALL', {
          idCategoriaRecurso: categoriaRecursoSel.value,
          idTipoRecurso: tipoRecursoSel.value,
          query: '',
          page: 1,
          limit: 1000,
          sortBy: '',
        })
        .then(response => {
          if (response.items) {
            subTiposRecurso.value = response.items
            subTipoRecursoSel.value = {}
          }
        })
        .catch(error => {
          emit('error-data', error)
        })
    }

    const saveForm = async () => {
      if (destinoSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar Destino' } } })
        return
      }

      if (formData.value.nombre === undefined) {
        emit('error-data', { title: 'Ha ocurrido un error', message: 'Debe ingresar nombre del atractivo', type: 'danger' })
        return
      }

      if (formData.value.descripcion === undefined) {
        emit('error-data', { title: 'Ha ocurrido un error', message: 'Debe ingresar descripción del atractivo', type: 'danger' })
        return
      }

      if (dptoSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar departamento' } } })
        return
      }

      if (provSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar provincia' } } })
        return
      }

      if (distSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar distrito' } } })
        return
      }

      if (categoriaRecursoSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar categoría del recurso' } } })
        return
      }

      if (tipoRecursoSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar tipo del recurso' } } })
        return
      }

      if (subTipoRecursoSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar sub tipo del recurso' } } })
        return
      }

      formData.value.destino = {
        idDestinoTuristico: destinoSel.value,
      }
      formData.value.idCategoriaRecurso = categoriaRecursoSel.value
      formData.value.idTipoRecurso = tipoRecursoSel.value
      formData.value.idSubtipoRecurso = subTipoRecursoSel.value
      formData.value.ubigeo = {
        idUbigeo: distSel.value,
      }

      const service = 'plataforma/ATRACTIVO_CREATE'
      await store.dispatch(service, formData.value)
        .then(async response => {
          emit('refetch-data', response)
          emit('update:is-toggle-sidebar', false)
        })
        .catch(error => {
          emit('error-data', error)
          emit('update:is-toggle-sidebar', false)
        })
    }

    const toggleSidebar = async val => {
      emit('update:is-toggle-sidebar', val)
      getDestinos()
      getCategoriasRecurso()
      if (props.formType === 'new') {
        titleForm.value = 'Agregar Atractivo'
        getUbigeos('dpto', '')
        getUbigeos('prov', dptoSel.value.slice(0, 2))
      }
      if (props.formType === 'edit') {
        titleForm.value = 'Editar Atractivo'
        formData.value = props.dataEdit
        formData.value.ubigeo = props.dataEdit.ubigeo
      }
    }

    return {
      formData,
      isBusy,
      saveForm,
      toggleSidebar,
      resetForm,
      configDP,
      editorOption,
      titleForm,
      destinos,
      destinoSel,
      getDestinos,
      dptoSel,
      provSel,
      distSel,
      dptos,
      provs,
      dists,
      getUbigeos,
      getTiposRecurso,
      getSubTiposRecurso,
      categoriasRecurso,
      categoriaRecursoSel,
      tiposRecurso,
      tipoRecursoSel,
      subTiposRecurso,
      subTipoRecursoSel,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';

#new-edit-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
[dir=ltr] .ql-snow .ql-icon-picker .ql-picker-label svg {
  margin-top: -4px;
}
</style>

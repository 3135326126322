<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div
        class="sidebar-shop"
        :class="{'show': mqShallShowLeftSidebar}"
      >
        <b-row>
          <b-col cols="12">
            <h6 class="filter-heading d-none d-lg-block">
              Opciones de Búsqueda
            </h6>
          </b-col>
        </b-row>

        <!-- Filters' Card -->
        <b-card>
          <!-- Multi Range -->
          <div class="multi-range-price">
            <h6 class="filter-title mt-0">
              Destinos
            </h6>
            <b-form-checkbox
              v-for="option in destinosTuristicos"
              :key="option.idDestinoTuristico"
              v-model="idsDT"
              :value="option.idDestinoTuristico"
              class="mb-1"
              @change="applyFilter"
            >
              <span class="align-text-bottom line-height-1">{{ option.nombre }}</span>
            </b-form-checkbox>
          </div>

          <!-- Multi Range -->
          <div class="multi-range-price">
            <h6 class="filter-title">
              Experiencias Turísticas
            </h6>
            <b-form-checkbox
              v-for="option in experiencias"
              :key="option.idClasificacion"
              v-model="idsEXP"
              :value="option.idClasificacion"
              class="mb-1"
              @change="applyFilter"
            >
              <span class="align-text-bottom line-height-1">{{ option.nombre }}</span>
            </b-form-checkbox>
          </div>
        </b-card>
      </div>
    </div>

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BRow, BCol, BCard, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
  },
  props: {
    destinosTuristicos: {
      type: Array,
      required: true,
    },
    experiencias: {
      type: Array,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  setup(_, { emit }) {
    const idsEXP = ref([])
    const idsDT = ref([])
    const filters = ref({})
    filters.value.idsEXP = []
    filters.value.idsDT = []

    const applyFilter = () => {
      filters.value.idsEXP = idsEXP.value
      filters.value.idsDT = idsDT.value
      emit('apply-filter', filters)
    }

    return {
      idsEXP,
      idsDT,
      applyFilter,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
    ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
</style>
